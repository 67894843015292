import React, { FC, useContext, useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams, useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { Form } from '../../components/Forms/Form';
import { FormActionTypes } from '../../context/form-reducer';
import { useFormContext } from '../../hooks/useFormContext';
import { useFormsHubClient } from '../../hooks/useFormsHubClient';
import { Confirmation, FormError } from '../../models/form.models';
import { AppContext } from '../../context/app-context';
import { PushSiteImproveEvent } from '../../utils/siteimproveEvents';
import { mapPageFields } from './../../components/Forms/fieldMapper';
import { PushHotjarEvent } from '../../utils/hotjarEvents';
import './FormsController.scss';
import Loader from '../../components/loader/loader';
import { useLocalizer } from '../../hooks';

type Log = {
  eventType: string;
  timestamp: string;
};

export const FormsData: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formState, dispatch } = useFormContext();
  const { page, formId } = useParams();
  const [logName, setLogName] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  const { l } = useLocalizer('Form');

  var queryString = '';
  for (const [key, value] of searchParams.entries()) {
    queryString = `${queryString}${queryString && '&'}${key}=${value}`;
  }

  const formsClient = useFormsHubClient(formId || '0', queryString);

  const form = formState.form;
  const { state } = useContext(AppContext);

  const focusRef = React.useRef<any>();

  const allConfirmed = formState.confirmations.every((c) => c.isConfirmed);
  const noValidaitonErrors = Object.keys(formState.validationByFieldName).filter((k) => (formState.validationByFieldName[k] ?? []).length > 0).length === 0;

  useEffect(() => {
    if (formId && formState.formCreated && logName === null) {
      setLogName(`pageActivityLog-${formId}`);
    }
  }, [formId]);

  const getLogs: (logName: string | null) => Log[] = (logName) => {
    if (!logName) return [];

    const logs = localStorage.getItem(logName);
    return logs ? JSON.parse(logs) : [];
  };

  const storeLogs = (logs: Log[], logName: string | null) => {
    if (!logName) return;
    localStorage.setItem(logName, JSON.stringify(logs));
  };

  const logEvent = (eventType: string, logName: string | null) => {
    const now = new Date().toISOString();
    const logs = getLogs(logName);
    logs.push({ eventType, timestamp: now });
    storeLogs(logs, logName);
  };

  const clearLogs = (logName: string | null) => {
    if (!logName) return;
    localStorage.removeItem(logName);
  };

  const sendLogs = async (logName: string | null) => {
    const logs = getLogs(logName);
    if (!logs) return;
    clearLogs(logName);

    if (formState.formCreated) return;

    try {
      await formsClient.sendTimestampLogs(logs);
    } catch {
      // Get new and existing logs and store in localstorage if sending fails
      const newLogs = getLogs(logName);
      storeLogs([...logs, ...newLogs], logName);
      return;
    }
  };

  const fieldChange = async (event: any | string, pageIndex: number, field: any) => {
    const value = typeof event === 'string' ? event : event.target.value;

    if (!!value && !formState.formCreated) {
      formsClient.createForm();
    } else {
      sendLogs(logName);
      await formsClient.fieldChange(pageIndex, field.name, value);
    }

    dispatch({
      type: FormActionTypes.FormPageFieldChange,
      payload: {
        value: typeof event === 'string' ? event : event.target.value,
        pageIndex: pageIndex,
        field: field,
      },
    });
  };

  const fileUpload = async (file: any, pageIndex: number, field: any) => {
    if (!formState.formCreated) {
      await formsClient.createForm();
    }

    sendLogs(logName);

    const formData = new FormData();

    formData.append('file', file.file);
    formData.append('fieldName', field.name);

    try {
      const upload = await fetch(`/api/v1/form/${formId}/UploadFile`, {
        method: 'POST',
        body: formData,
      });

      if (upload.status === 422) {
        const validations = (await upload.json()) as Array<string>;

        return {
          status: false,
          message: validations.join(', '),
        };
      }

      const uploadedFile = await upload.json();

      await formsClient.fileChange(field.name);

      return {
        status: true,
        newId: uploadedFile.uploadName,
      };
    } catch (ex) {
      return {
        status: false,
        message: 'Something went wrong',
      };
    }
  };

  const fileDelete = async (id: string, pageIndex: number, field: any) => {
    let result = false;
    try {
      const deleteResult = await fetch(`/api/v1/form/${formId}/DeleteFile/${id}`, {
        method: 'DELETE',
      });

      result = true;

      if (deleteResult.status !== 404) await formsClient.fileChange(field.name);
    } catch (ex) {
      result = false;
    }

    return {
      status: result,
    };
  };

  const fileClick = async (file: any, pageIndex: number, field: any) => {
    const download = await fetch(`/api/v1/form/${formId}/GetFile/${file.uploadName}`);

    const fileBlob = await download.blob();

    saveAs(fileBlob, file.name);
  };

  const validatePages = async (pageIndex: number) => {
    const pageIsTouched: (num: number) => boolean = (pageIndex) => {
      const mappedPageFields = mapPageFields(formState.form.pages[pageIndex], formState, true);
      return mappedPageFields.some((field) => field.value !== null);
    };

    var pageIndexes = Array.from(Array(pageIndex + 1).keys()).filter(pageIsTouched);
    await formsClient.validatePages(pageIndexes);
    dispatch({ type: FormActionTypes.CompletePage, payload: pageIndex });

    if (pageIsTouched(pageIndex)) {
      const fields = mapPageFields(formState.form.pages[pageIndex], formState, true);
      fields
        .filter((f) => f.validations.length > 0)
        .forEach((field) => {
          PushSiteImproveEvent('FormFieldValidationError', field.name);
          PushHotjarEvent('error-occured');
        });
    }
  };

  const goToPage = async (currentPage: number, pageNumber: number) => {
    if (formState.formCreated) {
      try {
        await formsClient.saveForm(false);
      } catch (error) {
        dispatch({ type: FormActionTypes.SetError, payload: l('connectionLostError') });
        PushHotjarEvent('connectionLost');
        return;
      }
    }

    if (pageNumber > 0 && formState.formCreated) {
      validatePages(currentPage);
    }

    dispatch({ type: FormActionTypes.SetCurrentPage, payload: pageNumber });
    window.scrollTo(0, 0);

    focusRef.current.focus && focusRef.current.focus();
  };

  const confirmationChange = (confirmations: Array<Confirmation>) => {
    dispatch({ type: FormActionTypes.SetConfirmations, payload: confirmations });
  };

  const formSubmit = async () => {
    sendLogs(logName);
    dispatch({ type: FormActionTypes.SubmitClicked, payload: true });
    if (formState.formCreated && allConfirmed && noValidaitonErrors) {
      var response = await formsClient.submitForm();
      if (response.success) {
        dispatch({ type: FormActionTypes.FormSubmitted, payload: true });
        window.location.assign(response.url);
      }
    } else {
      dispatch({ type: FormActionTypes.SetError, payload: form.globalLabels.submitFailedLabel });
    }
    dispatch({ type: FormActionTypes.SubmitClicked, payload: false });
  };

  const dismissNotification = (id: number) => {
    dispatch({ type: FormActionTypes.RemoveError, payload: id });
  };

  useEffect(() => {
    if (!logName) return;

    const handleFocus = () => logEvent('focus', logName);
    const handleBlur = () => logEvent('blur', logName);
    const handleFormLoaded = () => logEvent('formLoaded', logName);
    const handleWindowUnloaded = () => logEvent('windowUnloaded', logName);

    handleFormLoaded();

    // Add event listeners for page focus, blur and beforeunload
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);
    window.addEventListener('beforeunload', handleWindowUnloaded);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('beforeunload', handleWindowUnloaded);
    };
  }, [logName]);

  useEffect(() => {
    if (formState.formCreated && !formState.urlUpdated) {
      navigate(
        {
          pathname: generatePath('/form/:formId/:page', { formId: formState.formId, page: formState.currentPage.toString() }),
          search: searchParams.toString(),
        },
        { replace: true }
      );
      dispatch({ type: FormActionTypes.UrlUpdated, payload: null });

      if (formState.lastEditedField) {
        formsClient.fieldChange(formState.currentPage, formState.lastEditedField.name, formState.lastEditedField.value);
      }
    }
  }, [formState.formCreated, formState.urlUpdated]);

  // Update url param when current page changes
  useEffect(() => {
    if (form && !form.submitted && formState.currentPage !== Number(page) && formId) {
      navigate({
        pathname: generatePath('/form/:formId/:page', { formId: formId, page: formState.currentPage.toString() }),
        search: searchParams.toString(),
      });
    }
    if (form && form.pages[formState.currentPage]?.pageType === 'formSummaryPage') {
      formsClient.validateForm();
      dispatch({ type: FormActionTypes.SummaryShown, payload: true });
    }

    if (form && !formState.formStarted) {
      dispatch({ type: FormActionTypes.FormStarted, payload: true });
    }
  }, [formState.currentPage, form]);

  // Update page when URL changes (navigate back/forward using browser history)
  useEffect(() => {
    if (form && page && formState.currentPage !== Number(page)) {
      goToPage(formState.currentPage, Number(page));
    }
  }, [location]);

  // Set correct page on first load
  useEffect(() => {
    if (formId)
      dispatch({
        type: FormActionTypes.SetFormId,
        payload: formId,
      });

    if (!page) {
      return;
    }
    dispatch({
      type: FormActionTypes.SetCurrentPage,
      payload: Number(page),
    });

    return () => {
      dispatch({ type: FormActionTypes.ResetState, payload: null });
    };
  }, []);

  //Makes sure the form is in the right language, if not it updates the language
  useEffect(() => {
    if (form !== undefined && form.language !== undefined && state.configuration.language.toLowerCase() !== form.language.toLowerCase()) {
      if (!formState.formCreated) {
        window.location.reload();
      } else {
        fetch(`/api/v1/form/${formState.formId}/ChangeLang`).then((res) => {
          if (res.ok) {
            window.location.reload();
          }
        });
      }
    }
  }, [state.configuration.language, formState.formCreated, form]);

  useEffect(() => {
    if (formState.formCreated && !formState.formStarted) {
      dispatch({ type: FormActionTypes.FormStarted, payload: true });
    }
  }, [formState.formCreated, formState.formStarted]);

  if (formState.form === undefined) {
    return <Loader />;
  }

  return (
    <div className="forms-controller">
      <div ref={focusRef} tabIndex={-1}></div>
      <Form
        submitButtonDisabled={!noValidaitonErrors}
        goToPage={goToPage}
        validatePages={validatePages}
        fieldChange={fieldChange}
        fileUpload={fileUpload}
        fileDelete={fileDelete}
        fileClick={fileClick}
        confirmationChange={confirmationChange}
        formSubmit={formSubmit}
        dismissNotification={(e: FormError) => dismissNotification(e.id)}
      />
    </div>
  );
};
