import { Button, Section, Timeline } from '@in/component-library';
import React, { FC } from 'react';
import useSWR from 'swr';
import { getCourseEnrollments } from '../../apis/get-courses';
import { ApplicationStatus } from '../../models/statuspage/application-status';
import { Recommendation } from '../../models/statuspage/recommendation';
import Loader from '../loader/loader';
import RecommendationCourseWrapper from '../recommendations/recommendation-wrapper';
import { Language } from '../../models';
import { SanityCaseProcessStep } from '../../models/sanity-case-process';
import { CaseProcess as CaseProcessModel } from '../../models/statuspage/case-process';
import { useTranslations } from '../../hooks/use-translations';

interface CaseProcessProps {
  caseProcessData: SanityCaseProcessStep[];
  activeStep: number | null;
  recommendations?: Recommendation[];
  language: Language;
  formWithAttachment?: CaseProcessModel | undefined;
  setTab: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const CaseProcess: FC<CaseProcessProps> = ({ caseProcessData, activeStep, recommendations, language, formWithAttachment, setTab }) => {
  const { data: enrollments } = useSWR(['api/courses/enrollments'], getCourseEnrollments);
  const { translations } = useTranslations();

  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  };
  
  return (
    <div className="timeline">
            <h1 className="form-status__title">{translations.formStatusTabStatus}</h1>

      {caseProcessData.length > 0 ? (
        <Timeline
          data={caseProcessData.map((step, index) => ({
            key: index, 
            header: step.header?.[language],
            date:
              (step.status === ApplicationStatus.PendingDecision && formWithAttachment?.status !== ApplicationStatus.PendingDecision)
                ? `est ${step.date.toLocaleDateString('no-No', options)}`
                : step.date.toLocaleDateString('no-No', options),
            children: (
              <div className="timeline__children">
                <p className="timeline__step-description">{step.description?.[language]}</p>
                {step.status === ApplicationStatus.UnderConsideration &&
                  recommendations?.map((recommendation, index) => (
                    <RecommendationCourseWrapper
                      key={`recommendation-${index}`}
                      caseProcessData={formWithAttachment}
                      course={recommendation}
                      existingEnrollment={enrollments?.filter((e) => e.id === recommendation.identifier)[0]}
                      headingLevel="h3"
                    />
                  ))}
                  {(step.status === ApplicationStatus.PendingDecision && formWithAttachment?.status === ApplicationStatus.PendingDecision) && <Button className='timeline__read-btn' onClick={setTab}>{translations.formStatusMessageReadResponse}</Button>}
              </div>
            ),
          }))}
          currentStep={activeStep !== null ? activeStep : undefined}
          headingLevel={2}
        />
      ) : (
        <Section bleed>
          <Loader />
        </Section>
      )}
    </div>
  );
};

export default CaseProcess;