import React, { FC } from 'react';
import { useTranslations } from '../../hooks/use-translations';
import { FormStatusMenuProps } from './form-status-menu';

interface FormStatusDesktopMenuProps extends FormStatusMenuProps {
  navItems: any[];
  selectedMenuElement: (index: number) => void;
}

const FormStatusMenuDesktop: FC<FormStatusDesktopMenuProps> = ({navItems, activeTab, selectedMenuElement }) => {
  const { translations } = useTranslations();

  return (
    <nav className="form-status-menu" role="navigation">
      <ul className="form-status-menu__list">
        {navItems.map((item, index) => (
          <li key={index} className={`form-status-menu__item ${activeTab === index ? 'form-status-menu__item--active' : ''}`}>
            <a
              href={item.href}
              aria-current={activeTab === index ? 'true' : undefined}
              onClick={(e) => {
                e.preventDefault();
                selectedMenuElement(index);
              }}
              className="form-status-menu__link"
            >
              {item.value}
              {index === 1 && item.unseenMessages ? ` (${item.unseenMessages} ${translations.formStatusUnreadMessage})` : ''}
              {index === 2 && item.referralParties ? ` (${item.referralParties})` : ''}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default FormStatusMenuDesktop;