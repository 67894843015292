import React, { FC } from 'react';
import { useTranslations } from '../../hooks/use-translations';
import { FeatureFlags } from '../../models';
import FormStatusConsent from '../form-status-consent/form-status-consent';
import { Button } from '@in/component-library';

export interface ReferralpartiesProps {
  municipality: string;
  bso: string[];
  consent: string;
  featureFlag: FeatureFlags;
}

const Referralparties: FC<ReferralpartiesProps> = ({ municipality, bso, consent, featureFlag }) => {
  const { translations } = useTranslations();

  return (
    <div className="form-status__referralparties">
      <h1 className="form-status__referralparties-title">{translations.formStatusConsent}</h1>
      {consent.toLocaleLowerCase() == 'true' ? (
          <FormStatusConsent municipality={municipality} bso={bso} consent={translations.formStatusBusinessSupportOrganization} />
      ) : (
        <>
          <FormStatusConsent municipality={municipality} bso={bso} consent={translations.formStatusGiveConsentDescription} />

          {featureFlag?.giveConsentButtonEnabled && (
            <Button size="medium" variant="solid">
              {translations.formStatusGiveConsentButtonText}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default Referralparties;
