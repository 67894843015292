import React, { FC } from 'react';
export interface FormStatusConsentProps {
  municipality: string;
  bso: string[];
  consent: string;
}

const FormStatusConsent: FC<FormStatusConsentProps> = ({ municipality, bso, consent }) => {
  return (
    <div className="form-status__consent">
      <h3 className="form-status__consent-heading">{consent}</h3>
      {municipality && (
        <ul className="form-status__consent-item">
          <li>
            <div className="form-status__consent-card">
              <p className="form-status__consent-card-paragraph">{municipality}</p>
            </div>
          </li>
        </ul>
      )}
      {bso && (
        <ul className="form-status__consent-item">
          {bso.map((bso, index) => (
            <li key={index}>
              <div className="form-status__consent-card">
                <p className="form-status__consent-card-paragraph">{bso}</p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FormStatusConsent;
