import { Message } from '../../models/statuspage/message';

interface NavItem {
  value: string;
  text: string;
  href: string;
  unseenMessages?: number;
  referralParties?: number;
  featureFlags?: { consentToReferralpartiesEnabled: boolean };
}

export const navItems = (translations: any, url: string, unseenMessages?: Message[], referralParties?: number, featureFlags?: { consentToReferralpartiesEnabled: boolean }): NavItem[] => [
  {
    value: translations.formStatusTabStatus,
    text: translations.formStatusTabStatus,
    href: `${url}/0`,
  },
  {
    value: translations.formStatusMessagesTitle,
    text: translations.formStatusMessagesTitle,
    href: `${url}/1`,
    unseenMessages: unseenMessages?.length,
  },
  ...(featureFlags?.consentToReferralpartiesEnabled
    ? [ {
      value: translations.formStatusConsent,
      text: translations.formStatusConsent,
      href: `${url}/2`,
      referralParties: referralParties,
    },] 
    : []
  ),
  {
    value: translations.formStatusYourInquiry,
    text: translations.formStatusYourInquiry,
    href: `${url}/3`,
  },
];
