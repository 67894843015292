export const apiDispatcher = <T extends {}>(
    url: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    data?: object | string,
    config?: DispatchConfig
  ): Promise<T> => {
    const contentType = config?.contentType ?? "application/json";

    return new Promise((resolve, reject) => {
      fetch(url, {
        method: method,
        body: method === "GET"
          ? null
          : data instanceof File
            ? data
            : JSON.stringify(data),
        credentials: "same-origin",
        headers: {
          "Content-Type": contentType
        }
      })
        .then(response => {
          if (response.status === 401) {
            window.location.href = `/login?redirect=${btoa(window.location.pathname + window.location.search)}`;
            return;
          }

          if (response.status === 204) {
            resolve({} as T);
            return;
          }

          if (!response.ok) reject(response);
  
          resolve(config?.resolver !== undefined ? config.resolver(response) : response.json());
        })
        .catch(error => {
          // TODO proper error handling at some point
          reject(error);
        });
    });
  };
  
  export interface DispatchConfig {
    contentType?: string;
    resolver?: (response: Response) => Promise<any>;
  }
