import React, { FC, useContext } from 'react';
import { Message } from '../../models/statuspage/message';
import FormStatusMenuMobile from './form-status-menu-mobile';
import FormStatusMenuDesktop from './form-status-menu-desktop';
import { useTranslations } from '../../hooks/use-translations';
import { ApplicationStatus } from '../../models/statuspage/application-status';
import { useMediaQuery } from '@in/component-library';
import { navItems } from './form-status-navItems';
import { AppContext } from '../../context/app-context';

export interface FormStatusMenuProps {
  formId: string | undefined;
  projectUrl?: string;
  formStatus: ApplicationStatus | undefined;
  unseenMessages?: Message[];
  selectedMenuElement: (tabIndex: number) => void;
  activeTab: number;
  referralParties?: number;
}

const FormStatusMenu: FC<FormStatusMenuProps> = ({ formId, projectUrl, formStatus, unseenMessages, selectedMenuElement, activeTab, referralParties }) => {
  const { state, dispatch } = useContext(AppContext);
  const { translations } = useTranslations();

  const [isMobile, setIsMobile] = React.useState<boolean>(false);

  const mediaQueryMobile = useMediaQuery('(max-width: 64em)');
  
  React.useEffect(() => {
    mediaQueryMobile !== undefined && setIsMobile(mediaQueryMobile.matches);
  }, [mediaQueryMobile]);

  const url = `/form/${formId}/status`; 
  
  const menuItems = navItems(translations, url, unseenMessages, referralParties, {
    consentToReferralpartiesEnabled: state.configuration.featureFlags.consentToReferralpartiesEnabled,
  });
  
  const handleSelectChange = (value: string) => {
    const selectedTab = menuItems.find(option => option.value === value);
  selectedTab && selectedMenuElement(menuItems.indexOf(selectedTab));
  };

  return isMobile ? (
    <FormStatusMenuMobile
      navItems={menuItems}
      selectedOption={menuItems[activeTab].value}
      handleSelectChange={handleSelectChange}
      formId={formId}
      projectUrl={projectUrl}
      formStatus={formStatus}
      unseenMessages={unseenMessages}
      selectedMenuElement={selectedMenuElement}
      activeTab={activeTab}
    />
  ) : (
    <FormStatusMenuDesktop
      navItems={menuItems}
      activeTab={activeTab}
      formId={formId}
      projectUrl={projectUrl}
      formStatus={formStatus}
      unseenMessages={unseenMessages}
      selectedMenuElement={selectedMenuElement}
    />
  );
};

export default FormStatusMenu;