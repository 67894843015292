import React, { FC, useEffect, useState } from 'react';
import { FaIcon, Section, FaIcons } from '@in/component-library';
import { useFormContext } from '../../hooks/useFormContext';
import './SaveBar.scss';

const SaveBar: FC = () => {
  const { formState } = useFormContext();
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (document.documentElement.scrollTop > 60) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [sticky]);

  const getDateString = () => {
    const savetime = new Date(formState.form.modifiedOn);
    const today = new Date();
    const dateString = savetime.getDate() === today.getDate() ? savetime.toLocaleTimeString('no-NO') : savetime.toLocaleDateString('no-NO');
    return dateString;
  };

  let savecontent;
  switch (formState.saveState) {
    case 0:
      savecontent = (
        <span className="save-bar__saveText">
          <FaIcon className="save-bar__saveText--checkmark" name={FaIcons.CheckCircleLight} />{' '}
          {formState.form?.globalLabels.savedLabel + ' ' + (formState.form && getDateString())}
        </span>
      );
      break;
    case 1:
      savecontent = (
        <span className="save-bar__saveText">
          <FaIcon className="save-bar__saveText--spinner" name={FaIcons.SpinnerLight} /> {formState.form?.globalLabels.savingLabel}...
        </span>
      );
      break;
    case 2:
      savecontent = (
        <span className="save-bar__saveText">
          <FaIcon className="save-bar__saveText" name={FaIcons.ExclamationCircleLight} /> {formState.form?.globalLabels.saveFailedLabel}
        </span>
      );
      break;
    default:
      break;
  }
  if (formState.form && !formState.form.submitted) {
    return (
      <>
        <Section wide className={'save-bar ' + (sticky && 'save-bar--fixed')}>
          <span className="save-bar__title data-hj-suppress">
            {
              formState.form.fields.find((field: any) => {
                return field.name === 'name';
              }).value
            }
          </span>
          {savecontent}
        </Section>
        <Section className="save-bar--buffer"></Section>
      </>
    );
  }
  return <></>;
};

export default SaveBar;
